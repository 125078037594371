<template>
  <div class="wrap">
    <div class="content bg">
      <div class="news">
        <h2>干部病房三科（综合科）锻造健康管理服务的“医护天团”<span>2020-09-04</span></h2>
        <div class="new-con">
          <p style="margin-bottom: 0">拿到体检报告，面对一堆问题不知从何下手，医护人员第一时间帮你科学解读。</p>
          <p style="margin-bottom: 0">住院期间，躺在床上不知不觉中就能监测出各项生命体征，如有异常情况，第一时间展开救治。</p>
          <p style="margin-bottom: 0">病愈出院后，主治医生进行跟踪记录，让患者获得最佳的就医体验……</p>
          <p>这里就是中部战区总医院干部病房三科（综合科）。</p>
          <p><img src="../../assets/images/news/new4-img1.png" alt=""></p>
          <p>适应全民健康管理服务的新需求，实现由“被动医疗”向“主动健康”服务模式理念转型升级的全新实践，中部战区总医院干部病房三科（综合科）应运而生。它的诞生标志着医院院在“以治病为中心”转变到 “以战场需要和人民健康为中心”的征程上迈出了坚实的一步，开启了医院全生命周期全方位健康管理的新篇章。</p>
          <p>干部病房三科（综合科）位于中部战区总医院专科楼9楼，占地1800m2，设置有套房、单人间、双人间等各类病房20间，展开床位27张。</p>
          <p><img src="../../assets/images/news/new4-img2.png" alt=""></p>
          <p style="text-align: center; text-indent: 0">在医院的大力支持下<br>除配备有一流的医疗设备外<br>干部病房三科（综合科）依托成熟科技<br>实现科室运行高度智能化<br>全力打造“未来病房”</p>
          <p>科室拥有先进的英特沃德CDR+数字病区系统，数字化、智能化、自动化医疗及护理服务平台。病房实现了对病人各项生理指标、诊疗状态等进行集中监控及预警。</p>
          <p>在病区中央监护中心，医护端集成了睡眠无忧、病情监护、视频对讲、人机交互等功能，实现医护人员对住院患者随时、实时、全面监测和风险评估；患者端则通过床头配备的智控触屏显示系统集成了查房，健康宣教、问卷调查等功能，实现了医生查房时患者病情资料的实时获取，同时让患者更加直观、生动了解住院信息，获取个性化健康宣教内容并随时与病房医护进行可视化沟通交流。</p>
          <p><img src="../../assets/images/news/new4-img3.png" alt=""></p>
          <p>除此之外，MedCaptain MP-900静脉输注中央站实现了护士对输液信息的中央远程控制，确保患者输液过程随时可控，让护理工作更加及时全面，提升患者治疗体验，让干部病房三科（综合科）病房真正实现数字化、智能化管理。</p>
          <p><img src="../../assets/images/news/new4-img4.png" alt=""></p>
          <p>据干部病房三科（综合科）主任何威介绍，随着人们健康意识的不断提高，定期进行健康体检成为固定选项，但是体检报告出来后，如何读取其中的健康信息，有问题如何复查确诊，查出了症结，如何科学有效诊疗等，这些普遍关心的“痛点”，成为健康管理服务的切入点。</p>
          <p style="text-align: center; text-indent: 0">中部战区总医院<br>七十多年学科建设的深厚沉淀<br>为解决健康管理面临的痛点难题<br>实现多学科会诊提供了强力的保障<br>坚持健康体检是基础<br>健康评估是手段<br>健康干预是关键<br>健康促进是目的的理念<br>干部病房三科（综合科）为每一位住院体检者<br>提供检前的便捷<br>检中的周到<br>检后的温心的全程健康管理服务</p>
          <p><img src="../../assets/images/news/new4-img5.png" alt=""></p>
          <p>现代人生活节奏快、工作压力大，很多疾病的发病呈现出年轻化趋势。对于很多疾病来说，能否早期发现、及时治疗，是决定预后的关键。护士长林静表示，在医院学科大融合，医院大发展的背景下，干部病房三科（综合科）通过健康监测、健康评估和健康干预，定位个体化诊疗模式，探索肥胖、脂肪肝、血脂异常、糖耐量受损、骨量减少等疾病风险筛查体系；探索宫颈癌、肺癌、胃癌、结肠癌、乳腺癌等早期癌瘤筛查流程，通过及时干预，阻断疾病发生发展的进程，进而促进健康。</p>
          <p>在住院期间，干部病房三科（综合科）借助于于医院强大的专家队伍和科室智能化的数字化平台，全方位智能化管理，为患者提供个性化特色体检、综合分析体检结果、制定科学的治疗及康复方案、精心出院指导、进行健康动态追踪和指导。科室以科学、高效、优质、周密的体检及健康管理安排，更大限度地提高患者的健康管理水平，减低时间成本支出的同时提高健康水平。</p>
        </div>
        <div class="source"><a href="https://mp.weixin.qq.com/s/DSL44XrL2Lnklyeokne_5Q" target="_blank">阅读原文</a></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'News'
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 444px;
  margin-top: 76px;
  background: url("../../assets/images/banner4.png") no-repeat center;
  text-align: center;
  overflow: hidden;
  .text {
    display: inline-block;
    margin: 117px auto 0;
  }
  .icon {
    width: 105px;
    height: 105px;
    margin: 0 auto;
    background: url("../../assets/images/banner4-icon.png") no-repeat center;
  }
  .h2 {
    font-size: 30px;
    color: #fff;
    margin-top: 62px;
  }
}
.bg {
  background: #fafafa;
}
.content {
  position: relative;
  padding: 60px 0;
  margin-top: 76px;
  .news {
    width: 1200px;
    margin: 0px auto;
    overflow: hidden;
    h2 {
      text-align: center;
      margin-bottom: 30px;
      span {
        display: block;
        margin: 10px auto 0;
        font-size: 14px;
      }
    }
    .new-con {
      width: 80%;
      margin: 0 auto;
      p {
        margin-bottom: 30px;
        text-indent: 2em;
        line-height: 2.2;
        img {
          display: block;
          margin: 0 auto;
        }
        &.tac{
          text-align: center;
          margin-top: -30px;
        }
      }
    }
    .source a {
      font-size: 12px;
      color: #434343;
    }
  }
}
</style>
